.tabDetails {
  ul {
    li {
      margin-right: 10px;
      button {
        color: #000;
        border: 1px solid #dee5fb !important;
        &:hover,
        &:active,
        &:focus {
          color: #000;
        }
        &.active {
          background-color: rgb(222, 229, 251) !important;
          color: rgb(36, 59, 134) !important;
          border: 1px solid rgb(222, 229, 251) !important;
        }
      }
    }
  }
}
.tab-contentPart {
  .ServiceProfile-head {
    border-radius: 10px;
    background-color: #f9fafe;
    padding: 5px 10px;
    line-height: 26px;
    .ServiceProfile-part {
      font-size: 12px;
      > div {
        border-right: solid 1px #c8d2ed;
        width: 100%;
        padding: 5px 10px;
        &:last-child {
          border: none;
        }
      }
      span {
        font-weight: 500;
        &.material-icons-outlined {
          font-size: 13px;
        }
      }
      strong {
        font-size: 15px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .ServiceProfile-head {
      flex-direction: column;
      padding: 14px;
      .ServiceProfile-part {
        flex-wrap: wrap;
        justify-content: flex-start !important;
        > div {
          width: 25%;
        }
      }
    }
  }
  .service-profile {
    border: solid 0.5px #d1dcff;
    background-color: #ecf0fb;
    padding: 5px 8px;
    font-size: 12px;
    color: #243b86;
    border-radius: 25px;
    img {
      width: 24px;
      margin-right: 8px;
    }
    .user-detail {
      text-wrap: nowrap;
    }
  }
}
.insurancePart {
  box-shadow: 0px 2px 4px 0px #c8d2ed;
  border: solid 1px #dee5fb;
  padding: 40px;

  border-radius: 10px 10px 0 0;
  .rs-steps-horizontal {
    .rs-steps-item {
      overflow: visible;
      width: 25%;
      .rs-steps-item-content {
        height: auto;

        .stepHead-part {
          .step-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #d9d9d9;
            margin: 5px 5px 0 0;
          }
          .stepTitle {
            font-size: 14px;
            font-weight: 600;
            color: #243b86;
          }
          .steplabel {
            font-size: 14px;
            font-weight: 400;
            color: #243b86;
          }
          .stepStatus {
            font-size: 10px;
            color: #656364;
            span {
              font-size: 13px;
              margin-right: 4px;
            }
            &.rejected {
              span {
                color: #bc2e04;
              }
            }
          }
        }
        .rs-steps-item-title {
          &::after {
            display: none;
          }
        }
      }
      padding-left: 0;
      .rs-steps-item-icon-wrapper {
        display: none;
      }
    }

    .rs-steps-item-status-process {
      &::after {
        content: '';
        background-color: #243b86;
        height: 1px;
        display: flex;
        position: absolute;
        bottom: -21px;
        z-index: 996;
        width: 100%;
      }
      .stepHead-part {
        .step-icon {
          background-color: #ebeffc !important;
        }
      }
    }
    .rs-steps-item-status-finish {
      .stepHead-part {
        .step-icon {
          background-color: #ebeffc !important;
        }
      }
    }
  }

  .uploadDocumentPart {
    .uploadDocument {
      border-radius: 8px;
      border: solid 1px #d9d9d9;
      padding: 20px;
      .InsuranceFileUpload {
        display: flex;
      }
      @media screen and (max-width: 1024px) {
        .w-100 {
          flex-direction: column;
          .w-25 {
            width: 100% !important;
          }
          .InsuranceFileUpload {
            width: 100% !important;
            margin-left: 0 !important;
            .mt-4 {
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    .insurance-form {
      .light-blue-bg {
        .inputParent {
          input {
            background-color: #ebeffc;
          }
          input {
            background-color: #ebeffc;
          }
        }
      }
    }
  }
  .upload-doc {
    border: solid 1px #d9d9d9;
    border-radius: 8px;
    padding: 15px;
    .doc-file {
      background-color: #ebeffc;
      border-radius: 6px;
      padding: 10px;
      color: #6c6a72;
      font-size: 12px;
    }
  }
  .Documentbtn {
    .btn-outline-primary {
      background-color: #fff;
      border-color: #133696;
      color: #133696;
      &:hover {
        background-color: #e7edff;
      }
    }
  }
}
.action-icon-part {
  span {
    font-size: 12px;
    border-radius: 3px;
    color: #243b86;
    background-color: #d1dcff;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #133696;
      color: #fff;
    }
  }
  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
  }
}
.insurance-status-change {
  label {
    margin-bottom: 4px;
  }
  .insurance-status {
    button {
      text-align: left;
      padding-right: 12px;
      font-weight: 500;
    }
    &:hover {
      .action-icon-part {
        span {
          background-color: #133696;
          color: #fff;
        }
      }
    }
    .pending_approval {
      button {
        background-color: #ebeffc;
      }
    }
    .approved {
      button {
        background-color: #33a42912;
        color: #2a9221;
      }
    }
    .rejected {
      button {
        background-color: #fff8f6;
        color: #bc2e04;
      }
    }

    .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
      display: none;
    }
  }
}
.InsuranceFileUpload {
  button {
    text-align: left;
    background: none;
    border: dotted 0.5px #d9d9d9;
    font-weight: 400;
    &:hover {
      background: #ebeffc !important;
    }
  }
}
.insuranceTab {
  .rs-steps {
    cursor: pointer;
    .rs-steps-item:nth-child(1) {
      .step-icon {
        background: url(../../assets/images/step1-hover.svg) center center no-repeat;
      }
    }
    .rs-steps-item:nth-child(2) {
      .step-icon {
        background: url(../../assets/images/step2.svg) center center no-repeat;
      }
      &.rs-steps-item-status-process,
      &.rs-steps-item-status-finish {
        .step-icon {
          background: url(../../assets/images/step2-hover.svg) center center no-repeat;
        }
      }
    }
    .rs-steps-item:nth-child(3) {
      .step-icon {
        background: url(../../assets/images/step3.svg) center center no-repeat;
      }
      &.rs-steps-item-status-process,
      &.rs-steps-item-status-finish {
        .step-icon {
          background: url(../../assets/images/step3-hover.svg) center center no-repeat;
        }
      }
    }
    .rs-steps-item:nth-child(4) {
      .step-icon {
        background: url(../../assets/images/step4.svg) center center no-repeat;
      }
      &.rs-steps-item-status-process,
      &.rs-steps-item-status-finish {
        .step-icon {
          background: url(../../assets/images/step4-hover.svg) center center no-repeat;
        }
      }
    }
  }
}
.paymentDetails-form {
  .rs-picker-select {
    .rs-stack {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
}
.payment-service-detail {
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
}
.tabContent .rs-dropdown .rs-dropdown-menu {
  z-index: 999;
}
