.reusableBtn {
  padding: 3px 16px;
  border-radius: var(--btnRadius);
  font-size: var(--btnFontSmd);
  line-height: 18px;
  font-weight: var(--semiBoldWeight);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.reusableBtnDisabled {
  background: var(--lightGrey);
  cursor: unset;
  color: var(--disabledTextColor);
}

.reusableBtnActive {
  background: var(--themeDarkColor);
  cursor: pointer;
  color: var(--whiteColour);
}

.reusableBtnIcon {
  height: 18px;
  width: 18px;
}

.reusableBtnIcon1 {
  height: 18px;
  width: 18px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.reusableBtn:hover {
  opacity: 0.8;
}

.btn-outline-success,
.btn-outline-success:hover {
  color: var(--primaryMain) !important;
  background-color: unset !important;
  border: 1px solid var(--primaryMain) !important;
  cursor: pointer;
}
