.settingsBody {
  padding: 0 0px 50px;
}

.settingsBody .linksFlexDiv {
  flex-wrap: wrap;
}

.cardsParent {
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
}

.settingsShowData {
  border: 1px solid var(--inputBorderColor);
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  overflow: hidden;
}

.settingFormOutline {
  border: 1px solid var(--lightGrey);
  padding: 16px 16px 0 16px;
}

.settingFormOutline .formTitle {
  font-weight: var(--semiBoldWeight);
  margin-bottom: 24px;
  font-size: var(--normalFontxm);
}

.assign-goal-daypart .formTitle {
  font-weight: var(--semiBoldWeight);
  font-size: var(--normalFontxm);
}

.measuringForm .flexmeasuringForm {
  gap: 10px;
}

.showImageStyle {
  max-width: 160px;
  max-height: 160px;
  object-fit: cover;
  border-radius: 5px;
}

.thumbImagesIcons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.thumbImagesIcons img {
  max-height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.userDetail-metaDetail {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--disabledTextColor);
  font-size: var(--normalFontSm);
  text-transform: capitalize;
}

.medicine-form .action-images {
  gap: 6px;
}

.medicine-form .action-images img {
  cursor: pointer;
  max-height: 24px;
  max-width: 24px;
}

.audit-log-screen {
  background: var(--primaryLighter);
  margin-top: 24px;
  padding: 24px;
}

.audit-log-screen table {
  background: var(--whiteColour);
  color: var(--cardTitle);
  font-size: var(--normalFontMd);
}

.audit-log-screen table td {
  padding: 1rem;
}

.audit-log-screen .audit-log-red {
  color: var(--errorColor);
}

.audit-log-screen .audit-log-green {
  color: var(--greenFontColour);
}

.audit-log-left span {
  color: var(--themeDarkColor);
}

.audit-log-left .badge {
  margin-right: 1rem;
  background: var(--lightBlueBg);
  color: var(--whiteColour);
}

.audit-log-right div {
  margin-left: 1rem;
  color: var(--lightBlack);
}

.audit-log-right a {
  color: var(--themeDarkColor);
}

.audit-log-right .audit-log img.coachImage {
  margin-top: 0;
  height: 25px;
  width: 25px;
}

.img-thumbnail {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-thumbnail img {
  max-height: 40px;
  object-fit: contain;
}

.showGoals .GoalsTitle {
  font-size: var(--normalFontMd);
  text-transform: capitalize;
}

.showGoals .GoalsSubTitle {
  text-transform: capitalize;
  color: var(--disabledTextColor);
  font-size: var(--normalFontSm);
  text-transform: capitalize;
}

.questionDetails,
.GoalsTitle,
.userDetail-metaDetail,
.medicineDataList {
  white-space: nowrap;
}

/* Popup*/
.rs-modal-header-close svg {
  height: 20px;
  width: 20px;
}

.rs-modal {
  display: flex !important;
  align-items: center;
}

.rs-modal-lg,
.rs-modal-md,
.rs-modal-sm {
  min-height: calc(100% - (0.5rem * 2));
}

.rs-modal-content .settingFormOutline {
  border: none;
}

.rs-modal-dialog {
  width: 100%;
}

/* Popup*/

.css-b62m3t-container input:focus {
  box-shadow: none !important;
}

.day_part-btn .coreBtn {
  margin: 0;
  height: 39px;
  white-space: nowrap;
}

.daypart_box .rs-picker-default .rs-picker-toggle {
  z-index: 0;
}

.day_part-btn .material-icons {
  padding: 9px;
  background: #dcdcdc;
  border-radius: 10px;
}

.behaviourpart-bg {
  background-color: #f7f7f7;
}

.daypart_box {
  background-color: #f7f7f7;
  padding: 15px;
}

.daypart_box .inputlabel {
  font-weight: 600;
}

.goal-daypart {
  width: 33.33%;
  padding: 0 10px;
}

.eventdropdown div {
  width: 32%;
}

.daypart-input .rs-tag-closable {
  text-transform: capitalize;
}

/* .daypart-input div.rs-stack-item,
.behaviourpart_box div.rs-stack-item {
  flex-grow: 1 !important;
  overflow: inherit !important;
  display: inherit !important;
} */
.behaviourpart_box div.rs-stack-item span {
  text-wrap: wrap;
}

.behaviourpart_box .rs-stack {
  display: block;
}

.daypart-input {
  width: 38.7%;
}

.behaviourpart_box {
  width: 24%;
}

.add-question-btn {
  width: 28%;
}

.daypart-all-btn {
  width: 20%;
}

div.rs-check-item.rs-check-item-focus {
  background: transparent;
}

.behaviourpart-bg .rs-picker-toggle-value,
.rs-picker-select-menu-item {
  text-transform: capitalize;
}

.filterHeaderData .daypart_box {
  flex-direction: column;
}

.filterHeaderData .articleHeader {
  padding: 15px 0;
}

.filterHeaderData .add-question-btn {
  width: 100%;
}

@media only screen and (max-width: 1180px) {

  .measuringForm .flexmeasuringForm,
  .medicineNamerow {
    flex-direction: column;
  }

  .goal-daypart {
    width: 100%;
    padding: 0;
  }

  .daypart_box {
    flex-direction: column;
  }

  .add-question-btn,
  .daypart-all-btn {
    width: 100% !important;
  }

  .daypart-input,
  .behaviourpart_box {
    width: 100%;
  }
}

.medicineDataList .material-icons-outlined {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .settingsBody .linksFlexDiv {
    gap: 15px;
  }

  .daypart_box {
    flex-direction: column;
  }
}
