.showTeamMembersList {
  margin-bottom: 50px;
  .listUserImage {
    height: 40px;
    width: 40px;
    border-radius: 1000px;
    object-fit: cover;
  }

  .listUserImages {
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    object-fit: cover;
    position: relative;
    bottom: 0;
  }

  .assinedTeamImage {
    height: 14px;
    width: 14px;
    border-radius: 1000px;
    margin-right: 4px;
  }

  .userFullCard {
    width: 100%;
    position: relative;
    td {
      margin-bottom: 16px;
      padding: 4px 8px 0px 8px;
      vertical-align: top;
    }
  }
  .userImageView {
    align-items: center;
    gap: 16px;
  }

  .linesView {
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 1;
    align-items: baseline;
    white-space: nowrap;
    .teams {
      margin: 4px 0 0;
      padding: 4px;
      .linesView {
        .bottomTextView {
          font-size: var(--normalFontSm);
        }
      }
    }
  }

  .linesView1 {
    display: grid; // Switching to grid layout
    grid-template-columns: repeat(2, 1fr); // Two columns with equal width
    gap: 8px; // Space between items
    column-gap: 16px;
    line-height: 1;
    align-items: baseline;
    white-space: nowrap;
  
    .teams {
      margin: 8px 0 0;
      padding: 4px;
  
      .linesView {
        .bottomTextView {
          font-size: var(--normalFontSm);
        }
      }
    }
  }  

  .userName {
    font-weight: var(--semiBoldWeight);
    color: var(--themeDarkColor);
    text-decoration: underline;
    cursor: pointer;
    text-transform: capitalize;
    margin: 5px 0 0;
  }
  .bottomTextView {
    font-size: var(--normalFontMd);
    color: var(--lightBlack);
    white-space: nowrap;
  }

  .activeBtn {
    background-color: var(--successLighter);
    color: var(--successDarker);
    font-size: var(--normalFontSm);
    font-weight: var(--boldWeight);
    padding: 4px 10px;
    border-radius: 8px;
    line-height: 16px;
  }
  .days {
    color: var(--disabledTextColor);
  }
  .usersDivDisable {
    opacity: 0.5;
  }

  .phonenumberText {
    color: var(--lightBlack);
    font-weight: var(--boldWeight);
    font-size: var(--normalFontMd);
    padding: 2px 0 0 2px;
  }

  .patientAssign {
    font-size: var(--normalFontMd);
    font-weight: var(--boldWeight);
    padding: 4px 10px;
    border-radius: 8px;
    color: var(--cardTitle);
    color: var(--lightBlack);
    border: 1px solid var(--inputBorderColor);
  }

  .teams {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--disabledTextColor);
    border: 1px solid var(--inputBorderColor);
    padding: 4px 10px;
    border-radius: 30px;
    margin: 4px;
  }

  .teamMembersName {
    font-size: var(--normalFontSm);
    // font-weight: 2px;
  }

  .editBtn {
    border-radius: 35px;
    height: unset;
    font-size: var(--btnFontSmd);
    margin: unset;
    padding: 4px 8px;

    .btnImage {
      height: 18px;
      width: 18px;
    }
  }
  .statusDot {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: var(--warningMain);
    position: absolute;
    bottom: 0;
    right: 0;
    &.inactive,
    &.lapsed {
      background-color: var(--errorColor);
    }
    &.active {
      background-color: var(--successMain);
    }
  }
  .statusDotPatient {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: var(--warningMain);
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .userImage {
    position: relative;
  }

  .borderWhite {
    border: 1px solid #fff;
  }
}
