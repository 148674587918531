.btnImg {
  height: 13px;
  width: 13px;
}

.btnParentDiv, .patientBtnNew.rs-btn.rs-btn-default {
  gap: 11px;
  font-size: var(--normalFontMd);
  background-color: var(--bgBtnColor);
  padding: 10px 20px;
  border-radius: var(--btnRadius);
  border: 1px solid var(--themeDarkColor);
  cursor: pointer;
}
.patientBtnNew.rs-btn.rs-btn-default{
  color: var(--themeDarkColor);
  line-height: auto;
  padding:5px 20px;
  height: auto;
}
