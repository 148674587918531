.chipsTagsView {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .chips {
    color: var(--disabledTextColor);
    font-size: 9px;
    //font-size: var(--btnFontSmd);
    font-weight: var(--semiBoldWeight);
    border: 1px solid var(--lightGrey);
    border-radius: 35px;
    padding: 3px 10px;
    cursor: pointer;
    &:hover {
      color: var(--primaryMain);
      border-color: var(--primaryMain);
    }
    &.activeTagsStyle {
      &:hover {
        color: var(--whiteColour);
      }
    }
  }

  .activeTagsStyle {
    color: var(--whiteColour);
    background: var(--primaryMain);
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 3px 6px 3px 10px;
    cursor: unset;

    img {
      height: 16px;
      width: 16px;
      margin-top: -2px;
      cursor: pointer;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

.multiSelectContainer {
  background: unset;
  .searchWrapper {
    border: 1px solid var(--inputBorderColor);
    box-shadow: unset;
    min-height: 39px;
    border-radius: var(--inputRadius);
    color: var(--cardTitle);
    padding: 5px 12px;
    .chip {
      background: var(--lightGrey);
      color: var(--cardTitle);
      font-weight: var(--semiBold);
      font-size: var(--normalFontMd);
    }
  }
}
