.coreBtn, .tabClickBtn.rs-btn.rs-btn-default, .patientBtnNew.rs-btn.rs-btn-default{
  background-color: var(--themeDarkColor);
  margin: 18px 0;
  height: 48px;
  font-size: var(--normalFontxm);
  font-weight: var(--semiBoldWeight);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--inputRadius);
}



.btnImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.coreBtn:hover, .tabClickBtn.rs-btn.rs-btn-default:hover {
  background-color: var(--primaryMain);
}
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
  color: var(--primaryMain);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.coreBtn.form-control:disabled, .coreBtn:hover:disabled{
  background: none;
  background-color: #e9ecef;
  color:#666 !important;
}
