.paginationDiv {
  display: flex;
  gap: 8px;
  img {
    height: 24px;
    width: 24px;
  }
  .pageDiv {
    border-radius: 1000px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    border: 1px solid var(--primaryLighter);
  }

  .activePage,
  .pageDiv:hover {
    background-color: var(--primaryLighter);
    color: var(--themeDarkColor);
    border: 1px solid var(--themeDarkColor);
  }
}
.paginationPart {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  padding-left: 200px;
  box-shadow: 10px 0 20px #ededed;
  background-color: #fff;
  z-index: 99;
  .totalResult {
    font-size: var(--normalFontxm);
    margin-left: 30px;
    span {
      font-weight: var(--semiBoldWeight);
      margin-left: 6px;
    }
  }
}
.patientPerPage {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10;
}
.patientPagination {
  .paginationPart {
    position: initial;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    padding-left: 0;
    box-shadow: none;
    background-color: initial;
  }
}
body.bodyActive .paginationPart {
  padding-left: 60px;
}

@media only screen and (max-width: 767px) {
  .paginationPart {
    padding-left: 60px;
  }
}
