* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, Lato, sans-serif;
  font-style: normal;
}

:root {
  /* font Size */
  --normalFontXsm: 0.625rem;
  --normalFontSm: 0.75rem;
  --btnFontSmd: 0.813rem;
  --normalFontMd: 0.875rem;
  --normalFontxm: 1rem;
  --normalFontXl: 1.5rem;
  --normalFontXx: 1.75rem;
  --normalFontBig: 2.25rem;
  /* font Size */

  /* font weight */
  --lightWeight: 400;
  --middleWeight: 500;
  --semiBoldWeight: 600;
  --boldWeight: 700;
  /* font weight */

  /*Border Radius*/
  --inputRadius: 10px;
  --btnRadius: 10px;
  --otpInputRadius: 4px;
  --checkBoxRadius: 4px;
  /*Border Radius*/

  /*Theme Massage Colour*/
  --errorColor: #ff4242;
  --dustRed: #f5222d;
  --dustRedShadow: #f5222d80;
  --successLighter: #d8f5ce;
  --successDarker: #336b1f;
  --errorDarker: #812222;
  --successMain: #65d83c;
  --warningMain: #ffc225;
  --warningLighter: #fcedc7;
  --greenFontColour: #4a9d2d;
  --errorLighter: #ffe5e5;
  /*Theme Massage Colour*/

  /*Theme Common Colour*/
  --whiteColour: #ffffff;
  --blackColour: black;
  --disabledTextColor: #a9b2b9;
  --lightBlack: #434d56;
  --lightGrey: #eef1f3;
  --gray: #d9d9d9;
  --bodyBlankBG: #f0f2f5;
  --inputBorderColor: #d9dee2;
  --cardTitle: #1a2128;
  --placeHolderColor: #7b858f;
  --cardBackground: #f4f4f4;
  --cardBoxShadow: 0 0 9px 1px rgba(28, 39, 49, 0.05);
  --modalBackgroundColor: #00000033;
  --greyLight: #232c34;
  /*Theme Common Colour*/

  /* Blue Theme */
  --themeColor: #3c57a1;
  --themeDarkColor: #133696;
  --primaryLighter: #e9efff;
  --primaryMain: #3c5db7;
  --dashBoardCountColor: #0b1354;
  --primaryDarker: #072679;
  --lightBlueBg: #839de2;
  --bgBtnColor: rgba(19, 54, 150, 0.08);
  /* Blue Theme */
}

.hrLine {
  height: 1px;
  background-color: var(--inputBorderColor);
  border-radius: 100px;
  width: 100%;
}

a,
a:hover,
a:active {
  text-decoration: none;
}

.inputFieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.inputDetailsDiv {
  color: var(--disabledTextColor);
  font-size: var(--normalFontMd);
}

.capitalize {
  text-transform: capitalize;
}

.themeColor {
  color: var(--themeDarkColor);
}

.boldFont {
  font-weight: var(--semiBoldWeight);
}

/* Profile DropDown */
.profile-dropdown-menu .dropdown-profile {
  color: var(--blackColour);
  font-size: var(--normalFontxm);
}

.profile-dropdown-menu .dropdown-profile span {
  color: var(--themeColor);
  font-size: var(--normalFontSm);
}

.dropdown-profile-pic {
  margin-right: 10px;
}

.profile-dropdown-menu .logoutIconImage {
  height: 18px !important;
  padding: 0;
  margin: 0;
  width: 18px !important;
  margin-right: 9px;
  font-size: var(--normalFontMd);
}

.profile-dropdown-menu .profilesub_menu {
  display: flex;
  margin-left: 10px;
}

.profile-dropdown-menu .profilesub_menu:hover {
  color: var(--themeDarkColor);
}

.profile-dropdown-menu .profilesub_menu .material-icons-outlined {
  padding-right: 7px;
}

.profile-dropdown-menu::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 1rem;
  height: 1rem;
  background: var(--phoenix-black);
  top: -10px;
  right: 1.5rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: var(--phoenix-white);
  background: inherit;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #3838381a;
}

/* Profile DropDown */

.inputMsg {
  text-align: left;
  font-size: var(--normalFontMd);
  margin: 4px 0;
  font-weight: var(--lightWeight);
}

.alert-dismissible {
  display: flex;
  align-items: center;
  margin-top: -30px;
}

.alertSpace {
  min-height: 40px;
}

.alert-danger {
  color: #ff4242;
  background-color: #ffe5e5;
  border: unset;
}

.alert-danger .btn-close {
  color: #ff4242;
}

.lowercase {
  text-transform: lowercase;
}

.roundedDot {
  border-radius: 1000px;
  height: 10px;
  width: 10px;
  background-color: var(--themeDarkColor);
  display: inline-block;
  margin-right: 4px;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-stack-item {
  width: 100%;
  display: table;
}

.rs-picker-toolbar-right {
  text-align: right;
}

body {
  color: unset;
}

.main {
  max-width: 100%;
  min-height: 100vh;
}

.dropDownDiv>.dropDwonItems~.dropDwonItems {
  border-top: 1px solid var(--inputBorderColor);
}

.dropDownDiv {
  position: absolute;
  background: white;
  right: 24px;
  left: 32px;
  cursor: pointer;
  z-index: 997;
  border: 0.5px solid var(--inputBorderColor);
  border-radius: 6px;
}

.dropDwonItems:hover {
  background: var(--lightGrey);
}

.dropDwonItems {
  padding: 3px 10px;
}

.w-30 {
  max-width: 30%;
}

.w-40 {
  width: 40%;
}

.font-10px {
  font-size: var(--normalFontXsm);
}

.light-black {
  color: var(--lightBlack);
}

.font-12px {
  font-size: var(--normalFontSm);
}

.semiBold {
  font-weight: var(--semiBoldWeight);
}

.unitBox {
  border: 1px solid var(--inputBorderColor);
  height: 39px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-width: 60px;
  text-align: center;
  justify-content: center;
  background-color: var(--lightGrey);
}

.unitBoxLogging {
  border: 1px solid var(--inputBorderColor);
  height: 48px;
  border-radius: 10px 8px 8px 10px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-width: 120px;
  text-align: center;
  justify-content: center;
  background-color: var(--lightGrey);
}

.form-control:focus {
  background: var(--themeColor);
}

.rs-stack-item span {
  margin: 5px;
}

.activeDuration {
  color: #fff;
  background-color: var(--themeColor);
}

.wrapper {
  min-height: 100vh;
  height: 100%;
}

/*calendar Design*/
.rs-anim-fade.rs-anim-in.rs-picker-menu {
  z-index: 9999;
  /* width: 300px; */
  /* max-height: 330px; */
}



.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-select-menu.rs-picker-menu {
  z-index: 1100;
}

.rs-anim-fade.rs-anim-in.rs-picker-select-menu {
  max-width: 300px;
}

/* .rs-anim-fade.rs-anim-in.rs-picker-menu.rs-picker-date-menu {
  margin-top: 20px;
} */

/* .rs-anim-fade.rs-picker-date-menu {
  width: 300px;
} */

.filterModal .rs-anim-fade.rs-anim-in.rs-picker-menu {
  /* width: 300px; */
  margin-top: 0;
}

.rs-picker-daterange-predefined {
  height: 326px;
}

.rs-modal-wrapper .rs-anim-fade.rs-anim-in.rs-picker-menu {
  z-index: 9999;
}

.rs-anim-fade.rs-anim-in .rs-picker-daterange-predefined button {
  font-size: var(--normalFontSm);
  padding: 5px 5px;
}

.rs-picker-select-menu-item,
.rs-picker-none {
  padding: 8px 22px;
}

.rs-calendar-header {
  padding-left: 0;
  padding-right: 0;
}

.rs-calendar-body {
  padding: 4px 0 12px;
}

/* .rs-picker-menu .rs-calendar {
  min-width: 210px;
} */

.rs-stack-item span {
  margin: 0;
  font-size: var(--normalFontMd);
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  height: 28px;
  width: 28px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputRadius);
}

.rs-picker-default .rs-picker-toggle.rs-btn:focus,
.rs-picker-default .rs-picker-toggle.rs-btn:hover {
  border-color: var(--themeColor);
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 9px;
  padding-top: 8px;
}

@media only screen and (max-width: 980px) {
  /* .rs-anim-fade.rs-anim-in.rs-picker-menu {
    width: 250px;
  } */
}

/*calendar Design*/
.stickyHeader,
.wrapperContent {
  /* overflow: hidden; */
  margin-left: 200px;
}

.bodyActive .stickyHeader,
.bodyActive .wrapperContent {
  margin-left: 60px;
}

@media only screen and (max-width: 768px) {

  .stickyHeader,
  .wrapperContent {
    margin-left: 60px;
  }

  .patient-body.stickyHeader {
    margin-left: 60px;
  }

  .usersList,
  .articlePosts {
    padding: 0 16px;
  }

  .offcanvas-end {
    width: 320px !important;
  }
}

.graphTitleBlue {
  color: #0f3ab1;
}

.graphTitleRed {
  color: #c92f2f;
}

.graphTitleBlack {
  color: var(--lightBlack);
}

.graphTitleGreen {
  color: var(--greenFontColour);
}

.graphTitleYellow {
  color: #cc9d24;
}

.goal-sign-images-div {
  display: flex;
  justify-content: center;
}

.goal-sign-images {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border-radius: 20px;
  min-height: 100px;
}

.goal-sign-images div {
  background: #aa73f0;
  padding: 6px;
  border-radius: 50%;
  box-shadow: 0 0px 10px #d7d7d7;
  border: solid 2px #fff;
}

.goal-sign-images div:hover {
  background-color: #7d4dba;
}

.goal-sign-images img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.goal-sign-images .active-sign {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.show-graph-emoji img {
  height: 40px;
  width: 40px;
  padding: 10px;
  background: #aa73f0;
  border-radius: 10px;
}

.durationGroupBtn .btn-group {
  background: #efeff0;
  padding: 8px;
}

.durationGroupBtn .btn-group .btn.btn-light {
  background: none;
  border: none;
  margin: 0 5px;
  color: #000;
  font-weight: var(--middleWeight);
}

.durationGroupBtn .btn-group .btn.btn-light::after {
  content: '';
  background-color: #dfdfe1;
  width: 1px;
  height: 20px;
  position: absolute;
  right: -5px;
  top: 8px;
}

.durationGroupBtn .btn-group .btn.btn-light:last-child::after {
  display: none;
}

.durationGroupBtn .btn-group .btn.btn-light:hover,
.durationGroupBtn .btn-group .btn.btn-light.activeDuration {
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 3px #d5d5d5;
  color: #000;
  font-weight: var(--middleWeight);
}

.pointer {
  cursor: pointer !important;
}

/* Top Search Bar*/
.searchBarInput {
  position: relative;
  width: 100%;
}

.searchBarInput .searchbarIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.searchBarInput .searchbarIcon .material-icons-outlined {
  font-size: 20px;
  color: #232c34;
}

.searchBarInput input {
  padding-right: 33px;
}

/* Top Search Bar*/

.medicinesCategoryInfo {
  background: #f9f9f9;
}

.toggleBtn input[value='disable'] {
  height: 18px;
  width: 38px;
}

.toggleBtn input[value='enable'] {
  height: 18px;
  width: 38px;
  background-color: var(--themeDarkColor);
  border-color: var(--themeDarkColor);
}

.semiBoldWeight {
  font-weight: var(--semiBoldWeight);
}

.theme-border {
  border: 1px solid var(--primaryMain) !important;
}

.chips-padding {
  padding: 3px 10px !important;
}

.max-content {
  max-width: max-content;
}

.max-content-height {
  height: max-content;
}

.no-wrap {
  white-space: nowrap;
}

.gap-10 {
  gap: 10px;
}

.pl-10 {
  padding-left: 10px !important;
}

.wh-10 {
  height: 10px;
  width: 10px;
}

.dropdown-items-popover {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.profile-dropdown-menu .rs-dropdown-toggle.rs-dropdown-toggle-no-caret {
  background: none;
  padding: 0;
}

.daypart_box .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.medicineNamerow .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.assign-goal-daypart .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-btn .rs-picker-toggle-value {
  color: var(--cardTitle);
}

.daypart-time .inputParent {
  position: relative !important;
}

.react-timekeeper {
  position: absolute !important;
  z-index: 9;
}

.thumbImagesIcons>div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.thumbImagesIcons img.img-thumbnail:hover {
  border-color: #b2b2b2;
}

.cancelbtn {
  position: absolute;
  right: 0;
  cursor: pointer;
  color: #757575;
}

.imgcancelbtn {
  color: #333;
}

.hideCalender {
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin-left: 3px;
}

.hideCalender .rs-stack-item svg {
  left: 0 !important;
  top: 0 !important;
}

.hideCalender .rs-picker-toggle-placeholder,
.hideCalender .rs-picker-toggle-textbox,
.hideCalender .rs-picker-toggle-read-only,
.hideCalender .rs-picker-toggle-value,
.hideCalender .rs-picker-toggle-clean,
.hideCalender .rs-btn-close {
  display: none;
}

.hideCalender .rs-picker-toggle.rs-btn.rs-btn-default {
  border: none;
}

.hideCalender .rs-stack-item {
  padding: 4px;
}

/* Add Segment */

.segment-box .addsegment {
  border-bottom: solid 3px #fff;
}

.segment-box .addsegment:last-child {
  border: none;
}

.add-attribute-btn {
  white-space: nowrap;
}

.add-delete-btn .coreBtn {
  background: #dcdcdc;
  color: #000 !important;
}

/* Add Segment */

.sidebarScreen .rs-btn-default {
  padding-left: unset;
  background: unset !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.sidebarScreen .rs-btn-default svg {
  top: 2px;
}

.bodyActive .sideLinks-submenu .rs-dropdown-toggle {
  position: absolute;
  left: -60px;
  top: -17px;
  opacity: 0;
  width: 70px;
  height: 38px;
}

.bodyActive .sideLinks-submenu .rs-dropdown-menu {
  width: 200px;
}

.bodyActive .rs-dropdown-menu .sideLinks .sidebarNavText {
  display: block;
}

.medicine_dropdown {
  position: relative;
}

.frequency_cursor select {
  cursor: not-allowed !important;
}

.goalPopover.segmentlist-icon:hover .material-icons {
  color: #3c57a1;
}

.medicinename {
  font-size: var(--normalFontMd);
  text-transform: capitalize;
}

em,
em * {
  font-style: italic !important;
}

.inputTimePicker input {
  text-transform: capitalize;
}

.prefont_family {
  font-family: Inter, Lato, sans-serif;
  white-space: pre-wrap;
}

.medicine-alert .alert-dismissible {
  margin-top: 0;
}

.imagecancelbtn {
  position: absolute;
  margin: auto;
  left: 140px;
  cursor: pointer;
}

.videocancelbtn {
  position: absolute;
  margin: auto;
  left: 300px;
  cursor: pointer;
  color: #fff;
}

.rs-picker-select-menu.rs-picker-menu .rs-picker-select-menu.rs-picker-select-menu-items {
  max-height: 180px !important;
}

.segment-box .behaviourpart_box {
  width: 100%;
}

.segment-box .multiSelectContainer .searchWrapper {
  background-color: #fff;
}
