.tagsText {
  color: #656364;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tagContainer {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 0.5px solid #656364;
  background: #fff;
}
.feeText {
  color: #333;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.createdByText {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.serviceDetailsHeaderConteiner {
  border-radius: 0px 0px 10px 10px;
  border-bottom: 1px solid #d1dcff;
  background: #f1f5ff;
  align-items: center;
  margin-top: 8px;
  padding: 6px 0;
}
.table th {
  color: var(--black, #333);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  line-height: normal;
}
.table td {
  color: #333;
  text-align: center;

  /* text 1 reg 12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.viewDetailsTxt {
  color: #333;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.tableHeader {
  background-color: #f3f6ff;
}
.removeBottomMargin {
  margin-bottom: 0;
}
.tableContainer {
  border-radius: 10px;
  border: 1px solid #d1dcff;
  background: var(--Stark-White, #fff);
  box-shadow: 0px 2px 4px 0px #e4e7ff;
  margin: 13px;
}
.showDetailsContainer {
  justify-content: center;
  margin-right: 12px;
}
.CreatedByTextHeader {
  font-weight: 500;
  margin-right: 3px;
}
.recordPayment {
  color: #243b86;
  font-family: 'Inter';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 !important;
}
.paymentContainer {
  padding: 12px 12px 0 12px;
}
.cardDataMargin {
  border-radius: 10px !important;
  border: 1px solid #f1f5ff !important;
  background: #fafbff;
  padding: 0 !important;
}
.serviceEditIcon {
  cursor: pointer;
  border-radius: 4px;
  background: var(--brand-lighter-colour, #d1dcff);
  width: 20px;
  height: 20px;
  padding: 2px;
}
.statusText {
  font-family: 'Inter';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.statusTextDiv {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  align-self: center;
}
.buttonsDataStyle {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}
