.articleHeader {
  padding: 15px 24px;
}
.headerFiles {
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
}
.headerFilesRow {
  /* gap: 24px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 17px;
}

.articleBody {
  padding: 0 16px;
}

.articlePosts {
  padding: 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 51px;
}
