.postHeader .active,
.postHeader .inActive:hover {
  text-decoration: underline;
  color: var(--themeDarkColor);
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.postHeader .inActive {
  color: var(--disabledTextColor);
  cursor: pointer;
}

.postHeader {
  gap: 36px;
  flex-wrap: wrap;
}

.postHeaderPatient {
  gap: 10px;
  flex-wrap: wrap;
}

.postHeaderPatient .active,
.postHeaderPatient .inActive:hover {
  text-decoration: underline;
  color: var(--themeDarkColor);
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.postHeaderPatient .inActive {
  color: var(--disabledTextColor);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .postHeader {
    gap: 20px;
    flex-wrap: wrap;
  }
}
