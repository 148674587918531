.filterModal {
  .background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--modalBackgroundColor);
    display: flex;
    z-index: 1;
  }

  .whiteBackground {
    background-color: var(--whiteColour);
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    width: 400px;
    padding: 24px;
    padding-bottom: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .filterHeaderData {
      overflow: auto;
      height: 100vh;
    }
  }

  .filterHeader {
    font-weight: var(--semiBoldWeight);
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      cursor: pointer;
      padding: 0 0 0 10px;
    }
  }

  .filterHeadings {
    font-size: var(--normalFontMd);
    margin-bottom: 16px;
  }

  .categoryFileter {
    margin: 32px 8px 20px 8px;
    .inputParent {
      .leftIcon {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .renderFooter {
    align-self: flex-end;
    margin-top: auto;
    // position: absolute;
    bottom: 0;
    width: 100%;
    .footerButtons {
      display: flex;
      width: 100%;
      margin: 16px 0;
      justify-content: space-between;
      gap: 8px;

      .button {
        text-align: center;
        width: 100%;
        font-size: var(--normalFontMd);
        font-weight: var(--semiBoldWeight);
        padding: 10px 0;
        border-radius: 10px;
        cursor: pointer;
      }

      .clearBtn {
        color: var(--disabledTextColor);
        border: 1px solid var(--lightGrey);
      }

      .clearBtn:hover {
        color: var(--themeDarkColor);
        border-color: var(--themeDarkColor);
      }

      .applyBtn {
        color: var(--whiteColour);
        background: var(--primaryMain);
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .tagsFilter {
    .blurText {
      color: var(--disabledTextColor);
      font-size: var(--normalFontSm);
      margin-left: 10px;
    }
    input {
      height: 39px;
      font-size: var(--normalFontMd);
    }
  }

  .authorFilter {
    padding: 0 8px 0 8px;

    .searchWrapper {
      color: var(--themeDarkColor);

      #afterDiv,
      #beforeDiv {
        img {
          height: 16px;
          width: 16px;
          display: flex;
        }
      }

      #afterDiv {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .inputParent {
      img {
        height: 16px;
        width: 16px;
      }
    }

    .dropDownItemCustom:hover,
    .dropDownItemCustom:focus {
      input {
        border: 1px solid var(--themeDarkColor);
      }
    }
  }
  .hrLine {
    margin: unset;
  }
  .ml-8 {
    margin-left: 8px;
  }
}
.authorFilter {
  .dropDownItemCustom {
    position: relative;
    .dropDownDiv {
      right: 0;
      left: 0;
      top: 37px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .filterModal {
    .whiteBackground {
      width: 360px;
      padding: 15px;
    }
  }
}
