.teamBody {
  padding: 20px 16px 10px;
} 

.teamBody1 {
  padding: 10px 16px 0px;
}

.teamHeader {
  padding: 15px 24px;
}

.applyFilterBtn {
  height: 24px;
  font-size: 9px;
}

.usersList {
  padding: 0 40px;
}

/* site sticky part */
.profile-dropdown-menu::after {
  display: none;
}
.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 998;
  background: #fff;
}
/* .wrapperContent {
  overflow: auto !important;
  height: 100vh;
} */
.settingsBody .cardsParentDiv .cardTag {
  position: sticky;
  top: 150px;
  z-index: 996;
}
.boxShadow {
  box-shadow: 8px 0 5px #ededed;
}
.patientTabContent .p-2.w-35 > div:last-child {
  position: sticky;
  top: 90px;
  z-index: 996;
}
/* site sticky part */
